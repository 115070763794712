const getters = {
    GET_DOCK_INFO(state){
        return function (serial) {
            let info = state.deviceDockInfo[serial];
            if(info == undefined) {
                return null;
            }
            return info;
            // return data from store with query on args and self as this
        };
    },
    GET_DRONE_INFO(state){
        return function (serial) {
            let info = state.deviceDroneInfo[serial];
            if(info == undefined) {
                return null;
            }
            return info;
        };
    },
};
export default getters;
