const mapMutations = {
  MAP_STATE_INIT(mapState){
    mapState.isLeftPosition = false
    mapState.isRightAddVideoPosition = false
    mapState.defaultShowHeight = 15
    mapState.topDefault = 10
    mapState.positionAndLienShowButtonHeight = 35
    mapState.positionAndLienShowLineValue = 10
    mapState.defaultLineValue  = 1
    mapState.defaultButtonSize  = 34
    mapState.isTypeChangeShowButton = false
    mapState.isPositionAndLienShowButton = false
    mapState.isPointShowButton = false
    mapState.isCenterShowButton = false
    mapState.isNoFlightAreaShowButton = false
    mapState.isFlightAlertShowButton = false
    mapState.isFollowShowButton = false

    mapState.defaultButtonShowHeight = 10
    mapState.positionAndLienShowButtonTop  = 0
    mapState.typeChangeButtonTop = 0
    mapState.pointButtonTop = 0
    mapState.centerButtonTop = 0
    mapState.noFlightAreaButtonTop = 0
    mapState.flightAlertButtonTop  = 0
    mapState.followShowButtonTop = 0
    mapState.isPositionAndLienUnder = false
    mapState.isLngLatShowUnder = false
    mapState.positionAndLienUnder  = 0
    mapState.lngLatShowUnder  = 0
  },

  SET_IS_LEFT_BUTTON_POSITION(mapState, value){
    mapState.isLeftPosition = value;
  },
  SET_IS_RIGHT_ADD_VIDEO_POSTION(mapState, value){
    mapState.isRightAddVideoPosition = value;
    if(mapState.isRightAddVideoPosition) {
      mapState.defaultLineValue = 38;
    } else {
      mapState.defaultLineValue  = 1;
    }
  },

  SET_IS_POSITION_AND_LIEN_SHOW_BUTTON(mapState, value){
    mapState.isPositionAndLienShowButton = value;
    if(value) {
      mapState.defaultButtonShowHeight = mapState.defaultButtonShowHeight + mapState.positionAndLienShowButtonHeight + mapState.defaultShowHeight;
    }
  },
  SET_IS_TYPE_CHANGE_SHOW_BUTTON(mapState, value){
    mapState.isTypeChangeShowButton = value;
    var addTop = 0;
    if (value) {
      addTop = addTop + mapState.defaultButtonSize + mapState.defaultShowHeight;
    }
    mapState.typeChangeButtonTop = addTop;
  },
  SET_IS_POINT_SHOW_BUTTON(mapState, value){
    mapState.isPointShowButton = value;
    var addTop = 0;
    if (value) {
      addTop = addTop + mapState.defaultButtonSize + mapState.defaultShowHeight;
    }
    mapState.pointButtonTop = addTop;
  },
  SET_IS_CENTER_SHOW_BUTTON(mapState, value){
    mapState.isCenterShowButton = value;
    var addTop = 0;
    if (value) {
      addTop = addTop + mapState.defaultButtonSize + mapState.defaultShowHeight;
    }
    mapState.centerButtonTop = addTop;
  },
  SET_IS_USER_CENTER_SHOW_BUTTON(mapState, value){
    mapState.isUserCenterShowButton = value;
    var addTop = 0;
    if (value) {
      addTop = addTop + mapState.defaultButtonSize + mapState.defaultShowHeight;
    }
    mapState.userCenterButtonTop = addTop;
  },
  SET_IS_NO_FLIGHT_AREA_SHOW_BUTTON(mapState, value){
    mapState.isNoFlightAreaShowButton = value;
    var addTop = 0;
    if (value) {
      addTop = addTop + mapState.defaultButtonSize + mapState.defaultShowHeight;
    }
    mapState.noFlightAreaButtonTop = addTop;
  },
  SET_IS_FLIGHT_ALERT_SHOW_BUTTON(mapState, value){
    mapState.isFlightAlertShowButton = value;
    var addTop = 0;
    if (value) {
      addTop = addTop + mapState.defaultButtonSize + mapState.defaultShowHeight;
    }
    mapState.flightAlertButtonTop = addTop;
  },
  SET_IS_FOLLOW_SHOW_BUTTON(mapState, value){
    mapState.isFollowShowButton = value;
    var addTop = 0;
    if (value) {
      addTop = addTop + mapState.defaultButtonSize + mapState.defaultShowHeight;
    }
    mapState.followShowButtonTop = addTop;
  },

  SET_IS_POSITION_AND_LIEN_UNDER(mapState, value) {
    mapState.isPositionAndLienUnder = value;
  },
  SET_IS_LNG_LAT_SHOW_UNDER(mapState, value) {
    mapState.isLngLatShowUnder = value;
  },

  SET_POSITION_AND_LIEN_UNDER(mapState, value) {
    mapState.positionAndLienUnder = value;
  },
  SET_LNG_LAT_SHOW_UNDER(mapState, value) {
    mapState.lngLatShowUnder = value;
  },
};
export default mapMutations;
