import { fetchUserDetails } from "@/api/user";

const actions = {
  /**
   * LOGIN
   */
  LOGIN({ commit }, data) {
    localStorage.setItem("sessionTime", new Date().getTime());
    commit("LOGIN", data);
    commit("SET_USER_INFO", data);
  },
  /*
   * LOGOUT
   */
  LOGOUT({ commit }) {
    commit("LOGOUT");
    // window.location.href = "/";
  },

  /*
   * location
   * 로그인 후 이동 위치
   */
  LOCATION({ commit }, data) {
    commit("LOCATION", data);
  },

  /*
   * 자동 로그인
   */
  SET_AUTO_LOGIN({ commit }, data) {
    commit("SET_AUTO_LOGIN", data);
  },
  /**
   * fetch User 정보
   */
  getAccountInfo({ commit }) {
    let userId = localStorage.getItem("userId");
    fetchUserDetails(userId).then((res) => {
      if (res.status == 200) {
        let data = {
          accountId: res.data.data.accountId,
          userId: res.data.data.userId,
          status: res.data.data.status,
          userType: res.data.data.userType,
          phone: res.data.data.phone,
          name: res.data.data.name,
          loginId: res.data.data.loginId,
          departmentName: res.data.data.departmentInfo
            ? res.data.data.departmentInfo.name
            : "",
          departmentId: res.data.data.departmentInfo
              ? res.data.data.departmentInfo.departmentId
              : "",
        };
        commit("SET_USER_INFO", data);
      }
    });
  },
  SET_NAVBAR({ commit }, data) {
    commit("SET_NAVBAR", data);
  },
  SET_NAVBAR_VISIBLE({ commit }, data) {
    commit("SET_NAVBAR_VISIBLE", data);
  },
  SET_GNB_VISIBLE({ commit }, data) {
    commit("SET_GNB_VISIBLE", data);
  },
  SET_FOOTER({ commit }, data) {
    commit("SET_FOOTER", data);
  },
  SET_USER_INFO({ commit }, data) {
    commit("SET_USER_INFO", data);
  },
};

export default actions;
