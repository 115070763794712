const getters = {
  getPositionAndLineTop(state){
    return state.defaultShowHeight + state.topDefault;
  },
  getTypeChangeButtonTop(state){
    return state.defaultButtonShowHeight;
  },
  getMapPointTop(state){
    var getTypeChangeButtonTop = state.defaultButtonShowHeight;
    return getTypeChangeButtonTop + state.pointButtonTop;
  },
  getMapCenterTop(state){
    var resultTop = state.defaultButtonShowHeight + state.pointButtonTop
    return resultTop + state.centerButtonTop;
  },
  getMapUserCenterTop(state){
    var resultTop = state.defaultButtonShowHeight + state.pointButtonTop;
    resultTop = resultTop + state.centerButtonTop;
    return resultTop + state.userCenterButtonTop;
  },

  getNoFlightAreaButtonTop(state){
    var resultTop = state.defaultButtonShowHeight  + state.pointButtonTop
    resultTop = resultTop + state.centerButtonTop;
    resultTop = resultTop + state.userCenterButtonTop;

    return resultTop + state.noFlightAreaButtonTop;
  },

  getFollowButtonTop(state){
    var resultTop = state.defaultButtonShowHeight  + state.pointButtonTop
    resultTop = resultTop + state.centerButtonTop;
    resultTop = resultTop + state.userCenterButtonTop;
    resultTop = resultTop + state.noFlightAreaButtonTop;

    return resultTop + state.followShowButtonTop;
  },
  getFlightAlertButtonTop(state){
    var resultTop = state.defaultButtonShowHeight  + state.pointButtonTop
    resultTop = resultTop + state.centerButtonTop;
    resultTop = resultTop + state.userCenterButtonTop;
    resultTop = resultTop + state.noFlightAreaButtonTop;
    resultTop = resultTop + state.followShowButtonTop;

    return resultTop + state.flightAlertButtonTop;
  },
};
export default getters;
