import Vue from "vue";
import VueI18n from "vue-i18n";
// import Cookies from "js-cookie";
import VueCookies from "vue-cookies";
//import elementEnLocale from "../plugins/i18n/vuetify/en"; // element-ui lang
import koLocale from "./ko";
import enLocale from "./en";

Vue.use(VueI18n);

const messages = {
  ko: {
    // ...elementEnLocale,
    ...koLocale,
  },
  en: {
    // ...elementEnLocale,
    ...enLocale,
  },
};

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  // locale: "en",
  silentTranslationWarn: true,
  locale: VueCookies.get("language") || "ko",
  // set locale messages
  messages,
});

export default i18n;
