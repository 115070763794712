import Vue from 'vue';

const mutations = {
  DEVICE_INIT(state){
    state.deviceDockInfo = {};
    state.deviceDroneInfo = {};
  },

  SET_DEVICE_DOCK_INFO(state, data){
    if(state.deviceDockInfo[data.sn] === undefined){
      Vue.set(state.deviceDockInfo, data.sn, {})
    }

    if (data.host.modeCode !== null) {
      Vue.set(state.deviceDockInfo[data.sn], "base", data.host)
      return
    }

    if (data.host.jobNumber !== null) {
      Vue.set(state.deviceDockInfo[data.sn], "work", data.host)
      return
    }

    if (data.host.wirelessLink !== null) {
      Vue.set(state.deviceDockInfo[data.sn], "link", data.host)
    }
  },
  SET_DEVICE_DRONE_INFO(state, data){
    Vue.set(state.deviceDroneInfo, data.sn, data.host)
  },
  DEVICE_DRONE_OFFLINE(state, data){
    Vue.set(state.deviceDroneInfo, data.sn, null)
    delete state.deviceDroneInfo[data.sn];
  },
  SET_DOCK_DEBUG_STATUS(state, data){
    if (data.host.modeCode === null) {
      return;
    }

    if(state.deviceDockDebugStatus[data.sn] === undefined){
      state.deviceDockDebugStatus[data.sn] = {}
    }

    state.deviceDockDebugStatus[data.sn]["time"] = Date()
    state.deviceDockDebugStatus[data.sn]["droneSn"] = data.host.subDevice.deviceSn;

    state.deviceDockDebugStatus[data.sn]["droneIsCoverOpen"] = {
      status : data.host.coverState === 1 ? true : false,
      progress : 0
    } ;

    state.deviceDockDebugStatus[data.sn]["droneIsPutterOpen"] = {
      status : data.host.putterState === 1 ? true : false,
      progress : 0
    };
    state.deviceDockDebugStatus[data.sn]["droneIsOnline"] = {
      status : data.host.subDevice.deviceOnlineStatus,
      progress : 0
    };
    state.deviceDockDebugStatus[data.sn]["droneIsCharge"] = {
      status : data.host.droneChargeState.state,
      progress : 0
    };
  },
  SET_DEVICES_CMD_EXECUTE_INFO(state, data){
    console.log("SET_DEVICES_CMD_EXECUTE_INFO", data)
  },
  SET_MQTT_STATE (state, mqttState) {
    state.mqttState = mqttState
  },
  SET_CLIENT_ID (state, clientId) {
    state.clientId = clientId
  },

  SET_STATION_DOCK_CREATE_THUMBNAIL(state, data){
    let get = state.deviceThumbnailInfo[data.data.stationDeviceId]
    let send = {
      timestamp : data.timestamp,
      stationDeviceId : data.data.stationDeviceId,
      deviceSerial : data.data.deviceSerial,
      base64Encoder : data.data.base64Encoder,
      byteArray : data.data.byteArray,
      md5 : data.data.md5,
    }
    if(get === undefined) {
      Vue.set(state.deviceThumbnailInfo, data.data.stationDeviceId, send)
    } else {
      if(get.timestamp != data.timestamp) {
        Vue.set(state.deviceThumbnailInfo, data.data.stationDeviceId, send)
      }
    }

  },
};
export default mutations;
